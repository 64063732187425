

<template>
    <footer class="footer">
        <div class="container">
            <div class="row g-4 py-4">
              
                <div class="col-12 col-md-12 col-lg-9">
                    <div class="row g-4 footercolumnlnk" style="justify-content: space-around; ">
                        <div class="col-sm-12 col-md-3" v-if="$filters.IsNull(footerNavLinks1,true,null)!=null">
                            <h6>Customer Solutions</h6>
                            <ul class="nav flex-column">
                                
                                <li id="menu-item-17633" class="nav-item" v-for="link in footerNavLinks1">
                                    <router-link class="nav-link" :to="{ name: link.routeUrl,params:link.params}" @click="CloseLeftMenu" @click.native="scrollToTop">{{link.displayText}}</router-link>
                                    <!--<router-link v-if="GetUrlActive(link.params['url'])" class="nav-link" :to="{ name: link.routeUrl,params:link.params}" @click="CloseLeftMenu" @click.native="scrollToTop">{{link.displayText}}</router-link>-->
                                </li>
                            </ul>
                        </div>
                        {{indexValue}}

                        <div class="col-sm-12 col-md-3" v-if="$filters.IsNull(footerNavLinks2,true,null)!=null">
                            <h6>Useful Links</h6>
                            <ul class="nav flex-column">
                                <!-- list -->

                                <li id="menu-item-17634" class="nav-item" v-for="link in footerNavLinks2">
                                    <router-link class="nav-link" :to="{ name: link.routeUrl,params:link.params}" @click="CloseLeftMenu" @click.native="scrollToTop">{{link.displayText}}</router-link>
                                </li>
                               
                            </ul>



                        </div>
                        <div class=" col-sm-12 col-md-3" v-if="$filters.IsNull(footerNavLinks3.imageUrl,true,null)!=null">
                            <h6>Departments</h6>
                            <ul class="nav flex-column">
                                <li id="menu-item-17634" class="nav-item" v-for="link in footerNavLinks3">
                                    <router-link class="nav-link" :to="{ name: link.routeUrl,params:link.params}" @click="CloseLeftMenu" @click.native="scrollToTop">{{link.displayText}}</router-link>
                                </li>
                               

                            </ul>
                        </div>

                        <div class=" col-sm-12 col-md-3"  v-if="$filters.IsNull(TopProductCatgoryList,true,null)!=null">
                            <h6>Top Categories</h6>
                            <ul class="nav flex-column">
                                <!-- list -->

                                <li class="nav-item" v-for="(TopProductCatgory,index) in TopProductCatgoryList"><a @click="Productdetails(TopProductCatgory.id)" @click.native="scrollToTop" class="nav-link">{{TopProductCatgory.categoryName}}</a></li>
                               
                            </ul>
                        </div>

                    </div>
                </div>


                <div class="col-12 col-md-12 col-lg-3">
                    <h6>Contact Information</h6>
                    <div class="contactinfo">
                        <a class="phone" href="tel:(818) 767-3060" style="margin-bottom:5px;float:left;width:100%">{{companyAddress.phone}}</a> <br>
                        <a :href="'mailto:'+companyAddress.email" style="margin-bottom:10px;float:left;width:100%" class="underlinetext">{{companyAddress.email}}</a><br>
                        <font v-if="companyAddress.Address1 !='' || companyAddress.Address1 != null">{{companyAddress.Address1}}<span v-if="companyAddress.Address1 != null">, </span></font>
                        <font v-if="companyAddress.Address2 !=''">{{companyAddress.Address2}}<span v-if="companyAddress.Address2 != null">, </span> </font>

                        <font v-if="companyAddress.state !=''">{{companyAddress.state}}<span v-if="companyAddress.state != null">, </span> </font>
                        <font v-if="companyAddress.zip !=''">{{companyAddress.zip}}</font>
                    </div>
                    <div class="signup mt-3">
                        <h6>Sign up for our Newsletter</h6>
                        <div class="newsletter">
                            <newsletter v-if="$filters.IsNull(IsNewLetterRequired,true,true)==true"></newsletter>
                        </div>
                    </div>
                </div>



            </div>


        </div>

        <div class=" copyrightsect">
            <div class="container">
                <div class="row align-items-center">
                    <div class="col-12 col-md-12 col-lg-5">
                        <span class="small text-white">
                            <!--<p>&copy; 2023 Copyright East Valley Feed &amp; Tack. All rights reserved. Powered by <a href="#">Beenee.com</a></p>-->
                            <p>&copy; 2025 Copyright {{companyDomainName}}. All rights reserved. Powered by <a href="https://beenee.com" target="_blank">Beenee.com</a></p>
                        </span>
                    </div>
                    <div class="col-lg-3 col-md-6" style="text-align:right">
                        <img src="../../../../../assets/img/payment.png" />
                    </div>
                    <div class="col-lg-4 col-md-6" style="text-align:right">
                        <div class="social small" v-if="socialmedialinkList!=null && socialmedialinkList.length>0">
                            Follow us on
                            <span v-for="item in socialmedialinkList" class="p-1">
                                <a :href="[item.Url]" target="_blank"><i :class="[item.IconUsed.replace('fab', 'fa-brands')]"></i></a>
                            </span>
                        </div>
                    </div>

                    <div  @click.native="scrollToTop" class="back_top" style="text-align:right">
                        <i class="fa-solid fa-arrow-up"></i>
                    </div>
                </div>
            </div>
        </div>
    </footer>
    <Loading v-model:active="isLoading">
    </Loading>
</template>
<script>
    import newsletter from '@/views/NewsLetter/newsletter.vue'
    import UserFrontendService from '@/Services/frontEndService'
    export default {
        name: 'FooterView',
        props: ['footerNavLinks1', 'footerNavLinks2', 'footerNavLinks3', 'FooterworkusNavLinks'],
        components: {
            newsletter
        },
        data() {
            const searchModel = {
                searchItem: null,
                status: -1,
                sortColumn: 'CreatedOn',
                sortDir: 'desc',
                pageIndex: 0,
                pageSize: 10,
                userId: localStorage.getItem("userId"),
                companyId: localStorage.getItem("SupcompanyId")
            }
            return {
                socialmedialinkList: [],
                searchModel,
                TopProductCatgoryList: null,
                isMarketPlace: false,
                companyid: localStorage.getItem("companyId"),
                isfeatured: false,
                companyname: localStorage.getItem('companyName'),
                companyDomainName: localStorage.getItem('companyDomainName'),
                companyAddress: JSON.parse(localStorage.getItem('companyAddress')),
                userid: localStorage.getItem("comp-userId"),
                urlActive: true,
                isLoading: false,
                indexValue: '',
                IsAvailableUrl: '',
                Address2: '',

            }
        },
        created() {
            this.getListOfSocialLinks();
            //this.GetTopProductCatgoryList();

        },
        mounted() {
            this.GetTopProductCatgoryList();
        },

        methods: {

            //getListOfSocialLinks() {
            //    this.isLoding = true;
            //    UserFrontendService.GetSocialLinkList(this.searchModel.searchItem, this.searchModel.sortColumn, this.searchModel.sortDir, this.searchModel.pageIndex, this.searchModel.pageSize, '00000000-0000-0000-0000-000000000000', '00000000-0000-0000-0000-000000000000', 1).then(response => {
            //        console.log('response', response);
            //        this.socialmedialinkList = response.data.data;
            //    })
            //    setTimeout(() => { this.isLoding = false; }, 1000);

            //}
            GetUrlActive(routeUrl) {
                debugger
                this.urlActive = true;
                //this.IsAvailableUrl = this.footerNavLinks.filter(x => x.routeUrl == routeUrl);
                // console.log("indexValue",this.IsAvailableUrl);
                // this.indexValue =

                // link.params.url.getByIndex(9)
                if (routeUrl == 'ReturnPolicy' || routeUrl == 'DeliveryOptions' || routeUrl == 'TaxExemptions') {
                    this.urlActive = false;
                }
                return this.urlActive;
            },

            getListOfSocialLinks() {
                this.isLoding = true;
                UserFrontendService.GetSocialLinkList(this.searchModel.searchItem, this.searchModel.sortColumn, this.searchModel.sortDir, this.searchModel.pageIndex, this.searchModel.pageSize, this.userid, this.companyid, 1).then(response => {
                    console.log('response', response);
                    this.socialmedialinkList = response.data.data;
                })
                setTimeout(() => { this.isLoding = false; }, 1000);

            },
            GetTopProductCatgoryList() {

                this.isLoading = true;
                UserFrontendService.GetTopFiveCategory(this.isMarketPlace, this.isfeatured, this.companyid).then((response) => {

                    if (this.$filters.IsNull(response.data) != null) {
                        console.log('data', response.data);
                        this.TopProductCatgoryList = response.data;
                    }
                    this.isLoading = false;
                }).catch((error) => {
                    this.isLoading = false;
                })
            },
            Productdetails(Id) {
                this.isLoading = true;

                this.$router.push({ path: `/Product/${Id}` });
                setTimeout(() => { this.isLoading = false; }, 1000);
            },
            scrollToTop() {
                window.scrollTo(0, 0);
            },
        }
    }


</script>

<style scoped>

    .footer-link-items {
        column-count: 2;
        column-gap: 15px;
    }

        .footer-link-items .link-items {
            font-size: 14px;
            padding: 8px 0;
            margin: 0;
        }

    @media (max-width:767px) {
        .footer-link-items {
            column-count: 1;
        }
    }
</style>